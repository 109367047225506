// src/ImageScroller.js
import React from 'react';
import './ImageScroller.css';

const images = [
  'images\\isaac\\suit.jpg',
  'images\\isaac\\prom.jpg',
  'images\\isaac\\atman.jpg',
  'images\\isaac\\emma.jpeg',
  // Add more image URLs as needed
];

const ImageScroller = () => {
    return (
      <div className="scroller-container">
        <div className="scroller">
          {images.map((image, index) => (
            <img key={index} src={image} alt={`${index}`} className={`bobble bobble-${index % 5}`} />
          ))}
        </div>
      </div>
    );
  };
  
  export default ImageScroller;
