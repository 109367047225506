import React from 'react'
import './HomePage.css'
import GradientBackground from '../GradientBackground/GradientBackground.js'
import ImageScroller from '../ImageScroller/ImageScroller.js'
import '../OverflowHiddenPage/OverflowHiddenPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFilePdf } from '@fortawesome/free-solid-svg-icons'


const HomePage = () => {
  return (
    <div className="overflow-hidden">
<div className="homepage-container">
    <GradientBackground />
        <div className='scroll-please'>
            <h1 className="homepage-title">Hi, I'm <span className="homepage-name">Isaac</span>!</h1>

            <div className="homepage-about">
            <p className="intro-text">
                Waterloo CS '29 🎉
            </p>
            <p className="story-text">
                Throughout my childhood, I've always been interested-or at least, greatly intrigued, by programming and mathematics. My favourite subject throughout my first eight long years of elementary school was always math, which shifted to computer science throughout the duration of high school. I guess this happened because at the ripe old age of 12, my dad, wielding his fantasical Chinese math and engineering degrees, taught me how to code in Python for the first time. I built my own calculator. My own games. Small projects here and there.
                <br/><br/>I've loved AI ever since I watched the first Transformers movie, even though I didn't even know what AI was at the time. My other interests include playing badminton, writing (a lot), and playing video games (not as much compared to the COVID years).
            </p>

            <div className='homepage-images'>
            <ImageScroller />
            </div>



            </div>
        </div>

        <p className='contact-info'>
            
            
            <a className='contact-email' href="mailto:isaac.jiang66@gmail.com"> <FontAwesomeIcon icon={faEnvelope} className='envelope' /> &nbsp; Email</a>

            <a className='contact-resume' href='academic_resume_online.pdf' target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faFilePdf} className='resume' /> &nbsp; Resume</a> 

            </p>
    </div>
    </div>
  
  )
}

export default HomePage